import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { transition, trigger, style, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { UserService } from 'src/app/account/user.service';
import { AppInitializerService } from 'src/app/app-initializer.service';
import { User } from '../../interfaces/user';
import { TokenService } from 'src/app/account/token.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '.4s ease-out',
          style({
            opacity: 1,
          })
        ),
      ]),
    ]),
    trigger('ripple', [
      transition(':enter', [
        style({
          transform: 'scale(0)',
          opacity: 1,
        }),
        animate(
          '.4s ease-out',
          style({
            transform: 'scale(1)',
            opacity: 0,
          })
        ),
      ]),
    ]),
    trigger('plop', [
      transition(':enter', [
        style({
          transform: 'scale(0)',
        }),
        animate(
          '.15s ease-out',
          style({
            transform: 'scale(1)',
          })
        ),
      ]),
    ]),
    trigger('plop2', [
      transition(':enter', [
        style({
          transform: 'scale(0)',
        }),
        animate(
          '.15s .1s ease-out',
          style({
            transform: 'scale(1)',
          })
        ),
      ]),
    ]),
  ],
})
export class ErrorComponent implements OnInit {
  // @HostBinding('@fade')
  @Input() message: string;
  @Input() code: number;
  @Input() error: string;

  country = null;

  min = 4;
  max = 25;

  minLogin = 4;
  maxLogin = 25;

  user: User;

  LP = null;

  email: string;
  submitted = false;
  success = false;
  baselineUK = false;

  constructor(
    private dialog: AppService,
    private router: Router,
    private userService: UserService,
    private api: AppInitializerService,
    private token: TokenService
  ) {
    this.LP = this.api.params.landingPageLink.href;
    this.country = this.api.params.country;
  }

  ngOnInit() {
    this.baselineUK = this.api.params.country === 'UK';
    if (this.api.params.extCode === 'MX' && !!this.token.get()) {
      this.user = this.userService.user;
      this.email = this.user.email;
      if (this.user.operator === '33420') {
        this.LP = 'http://portal.shop/index.php/weezchat.html';
      }
      if (this.user.operator === '33409') {
        this.LP = 'http://mx.top-ofertas-mobile.com/mx/weezchat-prelanding-v3/';
      }
    }
  }

  close() {
    this.dialog.throwError(false);
  }
  gotToForgotPassword() {
    this.close();
    this.router.navigate(['/forget-password', { login: this.message }]);
  }
  onSubmit() {
    if (this.email && this.email !== '') {
      this.user.email = this.email;
      const readyToSend = isNaN(Number(this.email));
      if (readyToSend) {
        this.submitted = true;
        this.userService.update(this.user).subscribe(
          (res) => {
            this.close();
          },
          (err) => {
            this.email = '';
          }
        );
      }
    }
  }
}
