import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trackPageView } from '@snowplow/browser-tracker';
import * as CryptoJS from 'crypto-js';
import { UserService } from 'src/app/account/user.service';
import { AppInitializerService } from 'src/app/app-initializer.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  login: string;
  password: string;

  key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
  iv = CryptoJS.enc.Hex.parse('abcdef9876543210abcdef9876543210');

  facebook = null;
  twitter = null;
  instagram = null;

  loading = false;
  lp: string;
  free: boolean;
  social;

  baselineUK = false;

  constructor(
    private route: ActivatedRoute,
    private user: UserService,
    private router: Router,
    private dialog: AppService,
    private api: AppInitializerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.free = api.params.freeSubscription;
    this.lp = api.params.landingPageLink.href;
    this.social = api.params.socialMediaLinks;
    // trackPageView();
  }

  ngOnInit() {
    this.baselineUK = this.api.params.country === 'UK';
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('login')) {
        this.login = params.get('login');
        this.dialog.throwError({ message: this.login, code: '', error: 'AlreadyCreated' });
        this.user.showSubscribing(true);
      }
    });
  }
  onSubmit(): void {
    if (this.login && this.login !== '' && this.password && this.password !== '') {
      this.loading = true;
      // Impementing the Key and IV and encrypt the password+
      const encryptedPassword = CryptoJS.AES.encrypt(this.password, this.key, {
        mode: CryptoJS.mode.CBC,
        iv: this.iv,
      });
      this.user.signIn(this.login, encryptedPassword).subscribe((ok) => {
        this.loading = false;
        if (ok) {
          this.router.navigate(['/chat']);
        }
      });
    }
  }
}
